// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-collections-lost-and-found-js": () => import("./../../../src/pages/collections/lost-and-found.js" /* webpackChunkName: "component---src-pages-collections-lost-and-found-js" */),
  "component---src-pages-collections-nothingness-js": () => import("./../../../src/pages/collections/nothingness.js" /* webpackChunkName: "component---src-pages-collections-nothingness-js" */),
  "component---src-pages-collections-passage-through-time-js": () => import("./../../../src/pages/collections/passage-through-time.js" /* webpackChunkName: "component---src-pages-collections-passage-through-time-js" */),
  "component---src-pages-collections-stolen-memories-js": () => import("./../../../src/pages/collections/stolen-memories.js" /* webpackChunkName: "component---src-pages-collections-stolen-memories-js" */),
  "component---src-pages-collections-the-abyss-js": () => import("./../../../src/pages/collections/the-abyss.js" /* webpackChunkName: "component---src-pages-collections-the-abyss-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

